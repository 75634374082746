<template>
  <div class="modal">
    <div class="modal-header">
      <div class="title">
        <p style="line-height: 48px; margin-bottom: 0.25rem">
          <!-- Aditi <br />Musunur -->
          {{ taxonomyData.candidate.name }}
        </p>
      </div>
      <div class="designation" v-if="taxonomyData.candidate.designation">
        {{ taxonomyData.candidate.designation }}
      </div>
    </div>
    <div class="taxonomy-section">
      <div class="heading">
        <div class="minus-icon">
          <MinusOutlined class="svgicon" />
        </div>
        <div class="title">
          <h3 style="margin-bottom: 0">Taxonomy</h3>
        </div>
      </div>
      <div class="content-block">
        <div class="content">
          <div class="key-data">Industry :</div>

          <a-tag
            class="value-data"
            v-for="(industry, index) in taxonomyData.taxonomy.industries"
            :key="index"
          >
            {{ industry.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Sector :</div>

          <a-tag
            class="value-data"
            v-for="(sector, index) in taxonomyData.taxonomy.sectors"
            :key="index"
          >
            {{ sector.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Practice Area :</div>
          <a-tag
            class="value-data"
            v-for="(practiceArea, index) in taxonomyData.taxonomy.practiceAreas"
            :key="index"
          >
            {{ practiceArea.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Sub Practice Area :</div>

          <a-tag
            class="value-data"
            v-for="(subPracticeArea, index) in taxonomyData.taxonomy
              .subPracticeAreas"
            :key="index"
          >
            {{ subPracticeArea.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Functions :</div>

          <a-tag
            class="value-data"
            v-for="(function1, index) in taxonomyData.taxonomy.functions"
            :key="index"
          >
            {{ function1.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">SF Work Area :</div>

          <a-tag
            class="value-data"
            v-for="(sfWorkArea, index) in taxonomyData.taxonomy.sfWorkAreas"
            :key="index"
          >
            {{ sfWorkArea.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">SSF Tag :</div>

          <a-tag
            class="value-data"
            v-for="(ssfTag, index) in taxonomyData.taxonomy.ssfTags"
            :key="index"
          >
            {{ ssfTag.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Product :</div>

          <a-tag
            class="value-data"
            v-for="(product, index) in taxonomyData.taxonomy.products"
            :key="index"
          >
            {{ product.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Region :</div>

          <a-tag
            class="value-data"
            v-for="(region, index) in taxonomyData.taxonomy.regions"
            :key="index"
          >
            {{ region.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Segment :</div>

          <a-tag
            class="value-data"
            v-for="(segment, index) in taxonomyData.taxonomy.segments"
            :key="index"
          >
            {{ segment.name }}
          </a-tag>
        </div>
      </div>
    </div>
    <a-divider />
    <div class="work-experience-section">
      <div class="heading">
        <div class="minus-icon">
          <MinusOutlined />
        </div>
        <div class="title">
          <h3 style="margin-bottom: 0">Work Experience</h3>
        </div>
      </div>
      <div
        class="content-block"
        v-for="(experience, index) in taxonomyData.experiences"
        :key="index"
      >
        <div class="content-sub-section">
          <div class="title">
            <span>{{ experience.company }}</span>
          </div>
          <div class="period">
            <span>{{ displayDate(experience.fromDate) }}</span>
            <span> - </span>
            <span v-if="experience.isPresent">Present</span>
            <span v-else>{{ displayDate(experience.toDate) }}</span>
          </div>
        </div>
        <div class="content-description">
          {{ experience.jobDescription }}
        </div>
      </div>
    </div>
    <a-divider />
    <div class="qualification-section">
      <div class="heading">
        <div class="minus-icon">
          <MinusOutlined />
        </div>
        <div class="title">
          <h3 style="margin-bottom: 0">Education</h3>
        </div>
      </div>
      <div
        class="content-block"
        v-for="(qualification, index) in taxonomyData.qualifications"
        :key="index"
      >
        <div class="content-sub-section">
          <div class="title">
            <span>{{ qualification.degree }}</span>
          </div>
          <div class="period">
            <span>{{ qualification.year }}</span>
          </div>
        </div>
        <div class="content-description">{{ qualification.university }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { MinusOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive, onMounted, onUnmounted } from "vue";
import Services from "../../../services/apis";
import dayjs from "dayjs";

export default defineComponent({
  props: ["id"],

  setup(props) {
    let taxonomyData = reactive({
      candidate: {
        id: "",
        name: "",
        designation: "",
      },
      taxonomy: {
        industries: [
          {
            id: "",
            name: "",
          },
        ],
        sectors: [
          {
            id: "",
            name: "",
          },
        ],
        practiceAreas: [
          {
            id: "",
            name: "",
          },
        ],
        subPracticeAreas: [
          {
            id: "",
            name: "",
          },
        ],
        functions: [
          {
            id: "",
            name: "",
          },
        ],
        sfWorkAreas: [
          {
            id: "",
            name: "",
          },
        ],
        ssfTags: [
          {
            id: "",
            name: "",
          },
        ],
        products: [
          {
            id: "",
            name: "",
          },
        ],
        regions: [
          {
            id: "",
            name: "",
          },
        ],
        segments: [
          {
            id: "",
            name: "",
          },
        ],
      },
      experiences: [
        {
          id: "",
          company: "",
          designation: "",
          jobDescription: "",
          fromDate: "",
          toDate: "",
          isPresent: true,
        },
      ],
      qualifications: [
        {
          id: "",
          degree: "",
          name:"",
          month:"",
          university: "",
          year: "",
        },
      ],
    });

    const fetchTaxonomyData = (props) => {
      Services.candidateTaxonomy(props.id)
        .then((response) => {
          if (response.status === 200) {
            Object.assign(taxonomyData, response.data);
          }
        })
        .catch((e) => {
          if (e && e.response) {
            console.log("error :>> ", e);
          }
        });
    };

    const displayDate = (value) => {
      if (value) {
        return dayjs(value).format("YYYY");
      }
      return "";
    };

    onMounted(() => {
      fetchTaxonomyData(props);
    });

    return {
      taxonomyData,
      fetchTaxonomyData,
      displayDate,
    };
  },

  components: {
    MinusOutlined,
  },
});
</script>
<style lang="scss">
@import "../styles/widget.scss";
</style>
